<template>
  <a-result status="500"
            title="500"
            :sub-title="$t('common.500_cue_words')">
    <template #extra>
      <a-space :size="60">
        <a-button type="primary" @click="goHome">{{$t('common.back_home')}}</a-button>
        <a-button  @click="reLogin">{{$t('common.re_login')}}</a-button>
      </a-space>
    </template>
  </a-result>
</template>
<script>
import {
  Button,
  Result,
  Space
} from "ant-design-vue";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import { useTab } from '@/hooks/tabs/index';
export default {
  name: "error500",
  components: {
    AButton: Button,
    AResult: Result,
    ASpace: Space
  },
  setup () {
    const router = useRouter();
    const route = useRoute();
    const { dispatch } = useStore();
    const { delAllRoutes } = useTab();

    const goHome = () => {
      router.push({ path: "/dashboard" });
    }

    const reLogin = async ()=>{
      delAllRoutes();
      await dispatch("user/logout")
      var query = {};
      if(route.query.returnurl){
        query["returnurl"] = route.query.returnurl;
      }
      router.push({path:"/login",query:query});
    };

    return {
      goHome,
      reLogin,
    }
  }
}
</script>